.modal {
  text-align: center;
}
.modal::before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: -4px;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.web-popop{
padding: 0;
  .modal-dialog{
    width: 400px;
    .modal-content{
      background-image: -ms-linear-gradient(left, #8735DD 0%, #6D36C6 100%);
      background-image: -moz-linear-gradient(left, #8735DD 0%, #6D36C6 100%);
      background-image: -o-linear-gradient(left, #8735DD 0%, #6D36C6 100%);
      background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #8735DD), color-stop(100, #6D36C6));
      background-image: -webkit-linear-gradient(left, #8735DD 0%, #6D36C6 100%);
      background-image: linear-gradient(to right, #8735DD 0%, #6D36C6 100%);
      height: 530px;
      text-align: center;
      position: relative;
      padding: 25px 0;
      &:after{
        height: 17px;
        width: 17px;
        @include borderRadius(50%);
        background: #95d82b;
        position: absolute;
        left: 30px;
        bottom: -10px;
      }
      &:before{
        height: 22px;
        width: 22px;
        @include borderRadius(50%);
        background: #f8b541;
        position: absolute;
        right: 66px;
        top: 54px;
      }
    }
    .modal-header{
      border: none;
      background: transparent;
    }
    h2{
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      span{
        color: $brand-success;
      }
    }
    h5{
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 15px;
      font-weight: 400;
    }
    .modal-body{
      width: calc(100% + 60px);
      background: white;
      position: absolute;
      left: -30px;
      top: 65px;
      border-radius: 6px;
      padding: 25px 30px;
      &:before{
        height: 30px;
        width: 30px;
        @include borderRadius(50%);
        background: #2bd8ad;
        position: absolute;
        left: 10px;
        top: -15px;
      }
      &:after{
        height: 30px;
        width: 30px;
        background: #ffffff;
        position: absolute;
        right: 10px;
        bottom: -15px;
        border-bottom: 15px solid #41b1f8;
        @include borderRadius(50%);
      }
    }
    .form-group{
      margin-bottom: 8px;
      &:last-of-type{margin-bottom: 0}
      .form-control{
        height: 43px;
        border-color: #e3e3e3;
        border-radius: 4px;
        color: #999999;
        font-size: 13px;
        box-shadow: none;
        position: relative;
      }
      textarea{
        resize: none;
        &.form-control{
          height: 83px;
        }
      }
    }
    .sub-btn{
      position: relative;
      display: inline-block;
      &:before{
        height: 30px;
        width: 30px;
        @include borderRadius(50%);
        background: #2bd8ad;
        position: absolute;
        right: -13px;
        top: -5px;
      }
    }
    button{
      position: relative;
      background-image: -ms-linear-gradient(left, #8735DD 0%, #6D36C6 100%);
      background-image: -moz-linear-gradient(left, #8735DD 0%, #6D36C6 100%);
      background-image: -o-linear-gradient(left, #8735DD 0%, #6D36C6 100%);
      background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #8735DD), color-stop(100, #6D36C6));
      background-image: -webkit-linear-gradient(left, #8735DD 0%, #6D36C6 100%);
      background-image: linear-gradient(to right, #8735DD 0%, #6D36C6 100%);
      height: 51px;
      border-width: 2px;
      border-color: #3e3598;
      border-bottom: 0;
    }
    .email-dot{
      position: relative;
      &:before{
        height: 23px;
        width: 23px;
        @include borderRadius(50%);
        background: #41b1f8;
        position: absolute;
        right: -11px;
        top: 11px;
      }
    }
    .txt-dot{
      position: relative;
      &:before{
        height: 23px;
        width: 23px;
        @include borderRadius(50%);
        background: #9741f8;
        position: absolute;
        left: -11px;
        top: 32px;
      }
    }
    button.close {
      opacity: 1;
      background: red;
      height: 26px;
      width: 26px;
      @include borderRadius(50%);
      color: #f8e600;
      top: -10px;
      right: -10px;
      position: absolute;
      i{
        font-size: 11px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        line-height: 26px;
      }
    }
  }
  .modal{
    background: rgba(0, 0, 0, 0.76);
  }
}


/* ==================================@media Quriy=============================== */


/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .web-popop .modal-dialog {
    width: 90%;
  }
  .web-popop {
    .modal-dialog {
      .modal-body{
        width: calc(100% + 30px);
        left: -15px;
      }
    }
  }
  .web-popop {
    .modal-dialog {
      h2{
        font-size: 18px;
      }
      .modal-body{
        top: 60px;
      }
      .modal-content{
        height: 530px;
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}