.dot-txt{
  position: relative;
  &:after{
    width: 47px;
    height: 47px;
    background: #bcb5fb;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    left: -30px;
  }
  h3{
    position: relative;
    z-index: 9;

  }
}

form{
  textarea{
    resize: none;
    &.form-control{
      height: 100px;
    }
  }
  .form-control{
    width: 100%;
    border: #d6d1d1 1px solid;
    margin-bottom: 15px;
    height: 42px;
    font-size: 14px;
  }
  button{
    border: 2px solid $brand-primary;
    background: $brand-primary;
    padding: 11px 49px;
    font-size: 17px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    @include borderRadius(50px);
  }
}
.inner-txt{
  h3{
    font-size: 35px;
    color: #333;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 20px;
  }
  h6{
    color: $brand-primary;
    font-size: 30px;
    font-weight: 700;
  }
  h5{
    color: #848e9d;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
  }
  p{
    color: #696c70;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 35px;
  }
  ul{
    li{
      color: #696c70;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 15px;
      list-style-type: disc;
      padding-left: 15px;
    }
  }
}

section.about-page2 h2 {
  color: #282828;
  font-size: 46px;
  position: relative;
  font-weight: 300;
  strong{
    color: $brand-primary;
  }
}
section.about-page2 ul li {
  display: inline-block;
  position: relative;
  width: 310px;
  margin-right: 100px;
  margin-bottom: 50px;
}

section.about-page2 ul li h5 {
  font-size: 55px;
  font-weight: 800;
  font-family: 'Lato', sans-serif;
  margin-bottom: 0;
  line-height: 44px;
  color: #333;
}

section.about-page2 ul li i {
  position: absolute;
  right: 0;
  top: 0;
}

section.about-page2 ul li i img {}

section.about-page2 ul li span {
  display: block;
  background: $brand-success;
  padding: 10px;
  color: #221f1f;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

section.about-page2 ul li p {
  font-size: 16px;
  color: #535457;
  padding-top: 20px;
  padding-right: 50px;
  min-height: 80px;
}

section.about-page2 ul {
  margin-top: 60px;
}

.about-page2 p.txt-after {
  font-size: 26px;
  color: #333;
  line-height: 32px;
}
.about-page2 p.txt-after strong {
  vertical-align: baseline;
  color: $brand-primary;
}

section.about-page2 ul li:nth-child(3), section.about-page2 ul li:nth-child(6) {
  margin-right: 0;
}

section.about-page2 {
  padding: 20px 0 30px;
}
section.about-page2 ul li:nth-child(1) i {
  top: -26px;
}

section.about-page2 ul li:nth-child(3) i, section.about-page2 ul li:nth-child(3) i {}

section.about-page2 ul li:nth-child(6) i, section.about-page2 ul li:nth-child(1) i {
  top: 15px;
}

section.about-page2 ul li:nth-child(2) i, section.about-page2 ul li:nth-child(3) i {
  top: 20px;
}
.about-page2 p.txt-after {
  font-size: 26px;
}
.about-page2 p.txt-after strong {
  vertical-align: baseline;
}

section.services {
  background: #eeeeee;
  padding: 50px 0;
}
.ser-box {
  width: 100%;
  background: #fff;
  padding: 30px 30px;
  padding-left: 110px;
  margin: 5px 0 15px;
  color: #82818f;
  position: relative;
  font-size: 13px;
  font-weight: 300;
  min-height: 230px;
}
.ser-box i {
  position: absolute;
  left: 30px;
  top: 50px;
}

.ser-box h2 {
  color: #313131;
  font-size: 17px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}
.col-md-4.popular-serv .ser-box {
  text-align: center;
  padding: 87px 40px;
  position: relative;
}
.col-md-4.popular-serv .ser-box i {
  position: relative;
  top: 0;
  left: 0;
}
.services{
  h3{
    font-size: 28px;
    color: #333;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
  }
  p{
    color: #696c70;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 35px;
  }
}

.col-md-4.popular-serv .ser-box i.popular-tag {
  position: absolute;
  right: -10px;
  left: inherit;
  top: 30px;
}
.col-md-4.popular-serv .ser-box i {
  position: relative;
  top: 0;
  left: 0;
}

i.pen-animation {
  background: url(../images/animation-icon.png) no-repeat;
  width: 143px;
  height: 107px;
  position: absolute;
  top: -78px;
  left: -60px;
  z-index: -1;
}

.ser-txt{
  padding: 110px 0 60px;
  img{
    margin-top: -70px;
  }
}

section.animation-process {
  background: url(../images/pattern-gray.jpg) repeat-x left top #f2f2f2;
  padding: 50px 0;
  background-size: cover;
}
i.bulb-icon {
  background: url(../images/logo-icon.png) no-repeat;
  width: 143px;
  height: 170px;
  position: absolute;
  top: -108px;
  left: -60px;
  z-index: -1;
}
i.video-icon {
  background: url(../images/video-icon.png) no-repeat;
  width: 143px;
  height: 107px;
  position: absolute;
  top: -78px;
  left: -60px;
}
i.web-icon {
  background: url(../images/web-icon.png) no-repeat;
  width: 143px;
  height: 107px;
  position: absolute;
  top: -78px;
  left: -60px;
  z-index: -1;
}

section.animation-process [class^="col-"] h4 {
  font-size:  18px;
  color:  #313131;
  font-weight: 700;
  position:  relative;
  padding-left: 65px;
  margin-bottom: 19px;
  margin-top: 22px;
}

section.animation-process [class^="col-"] h4 span {
  font-size: 42px;
  color:  $brand-primary;
  font-weight: 400;
  position:  absolute;
  left:  0;
  top: 7px;
}

section.animation-process [class^="col-"] {
  color:  #848894;
  font-size:  13px;
  line-height: 22px;
  p{
    color: #696c70;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 35px;
  }
}

.ser_pack{
  .nav-tabs {
    margin-bottom: 60px;
    li {
      a{
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
h4.sub-heading {
  color: #585858;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
}
.ser_pack{
  h4{
    font-size: 30px;
    color: #000;
    font-weight: 700;
    text-align: center;
  }
  .pack{
    min-height: 1020px;
    ul{
      height: 820px;
    }
  }
}
.animate-pack{
  .pack{
    min-height: auto;
    ul{
      height: auto;
    }
  }
}
.logo-pack{
  .pack{
    min-height: auto;
    ul{
      height: 670px;
    }
  }
}

/* ==================================@media Quriy=============================== */

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  section.animation-process{
    padding: 30px 0;
  }
  .ser-txt{
    padding: 110px 0 40px;
  }
  .inner-txt{
    overflow: hidden;
    br{display: none}
    h3{
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 10px;
    }
    p{
      margin-bottom: 15px;
    }
    h5{
      font-size: 15px;
      margin-bottom: 15px;
    }
    h6{
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
  section.about-page2 {
    h2{
      font-size: 24px;
    }
    ul{
      margin-top: 30px;
      li{
        width: 100%;
        margin-bottom: 20px;
        h5{
          font-size: 35px;
        }
      }
    }
    p.txt-after{
      font-size: 18px;
      line-height: 34px;
    }
  }
  section.services{
    padding: 30px 0;
    br{
      display: none;
    }
    h3{
      font-size: 22px;
    }
  }
  section.portfolio [class^="col-md-3"] img {
    margin: 0 auto;
    display: block;
  }
  section.portfolio [class^="col-md-3"]  {margin-bottom:10px;}
  section.portfolio .video [class^="col-md-3"] img {
    margin-top: -33px;
  }
  .ser_pack{
    padding-top: 0;
    .pack{
      margin-bottom: 30px;
    }
  }
  .inner-banner{
    margin-top: -140px;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}