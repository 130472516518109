.home-slider{
  background: url("../images/banner.jpg") no-repeat;
  height: 840px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-top: -124px;
  .caption{
    padding-top: 12%;
    h1{
      font-size: 52px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
      span{
        font-style: italic;
        color: #fcf653;
      }
    }
    h4{
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }
    a{
      display: table;
      color: #fcf653;
      font-size: 22px;
      font-weight: 600;
      border: 2px solid #fcf653;
      height: 48px;
      margin: auto;
      margin-top: 30px;
      padding: 0 40px;
      border-radius: 50px;
      line-height: 48px;
    }
  }
  .row{
    margin-top: -50px;
  }
  .circle{
    width: 200px;
    z-index: 9;
    height: 200px;
    background: #fafafd;
    text-align: center;
    @include borderRadius(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    a{
      border: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
    i{
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background: #683be3;
      color: white;
      line-height: 36px;
      position: absolute;
      left: 0;
      border: 6px solid #fcf653;
      box-shadow: 0 0 2px #cab5b5;
      @include transition(0.2s linear);
      @include transition-delay(0.1s);
      opacity: 0;
    }
    &:nth-child(1){
      &:after{
        border-bottom: 2px dashed #5372d4;
        height: 64px;
        position: absolute;
        width: 100%;
        transform: rotate(43deg);
        top: 212px;
        left: 127px;
      }
    }
    h3{
      font-size: 20px;
      color: #000000;
      font-weight: 700;
      font-style: italic;
      line-height: 23px;
    }
    &:nth-child(2){
      position: relative;
      left: 160px;
      top: 40px;
      span{
        &.purple{
          height: 53px;
          width: 53px;
          border-radius: 50%;
          background: #683be3;
          top: 15px;
          right: 40px;
        }
        &.biscuit{
          background: #f2af50;
          bottom: 10px;
          left: 30px;
        }
      }
    }
    @include transition(0.5s);
    span{
      height: 33px;
      width: 33px;
      border-radius: 50%;
      background: #50ff96;
      position: absolute;
      @include transition(0.5);
    }
    &:nth-child(1){
      span{
        &.green{
          background: #fc9b53;
          left: 20px;
          bottom: 0;
        }
        &.brown{
          @include transition(0.5s);
          right: 30px;
          top: 30px;
        }
      }
    }
    &:hover{
      background: #fcf653;
      i{
        opacity: 1;
        left: -20px;
      }
    }
  }
  .looking{
    position: absolute;
    font-size: 22px;
    font-weight: 600;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    bottom: 90px;
    border-bottom: 2px dashed #5372d4;
    width: 27%;
    padding-bottom: 35px;
  }
  .right{
    .circle{
      float: right;
      span{
        &.brown{
          background: #ff5081;
          left: 0;
        }
        &.green{
          height: 53px;
          width: 53px;
          background: #fcf653;
          left: 18px;
          bottom: 0;
          z-index: 999;
        }
        &.biscuit{
          background: #8fdc49;
          top: 20px;
          left: 0;
        }
        &.purple{
          bottom: 0;
          top: inherit;
          right: 0;
        }
      }
      &:nth-child(2){
        position: relative;
        left: inherit;
        top: inherit;
        bottom: -220px;
        right: -10px;
      }
      &:nth-child(1){
        &:after{
          border-bottom: 2px dashed #5372d4;
          height: 64px;
          position: absolute;
          width: 50%;
          transform: rotate(129deg);
          top: inherit;
          left: -19px;
          bottom: -73px;
        }
      }
    }
  }
}
.our-industry{
  background: #ebf0f3;
  .txt{
    position: relative;
    &:after{
      width: 23px;
      height: 23px;
      background: #f8e600;
      @include borderRadius(50%);
      position: absolute;
      top: -13px;
      left: 115px;
      right: 0;
      margin: auto;
    }
    &:before{
      width: 47px;
      height: 47px;
      background: #bcb5fb;
      @include borderRadius(50%);
      position: absolute;
      bottom: -20px;
      left: 36px;
    }
    h2{
      font-size: 36px;
      color: #000;
      font-weight: 900;
      line-height: 38px;
      position: relative;
      z-index: 1;
      span{
        color: $brand-primary;
      }
    }
  }
  ul{
    li{
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      float: none;
      position: relative;
      display: table;
      margin: auto;
      &:after{
        background: url("../images/industry-after.png") no-repeat;
        height: 67px;
        width: 9px;
        position: absolute;
        right: 0;
        top: 30px;
      }
      &:last-of-type{
        &:after{display: none}
      }
      img{
        width: auto !important;
        margin: auto;
        margin-bottom: 25px;
        display: table;
      }
    }
  }
}
.stand_out{
  background: url("../images/standout-bg.png") repeat;
  .owl-carousel .owl-item img{
    width: auto;
    margin: auto;
    margin-bottom: 30px;
  }
  .crowd-slider{
    .box{
      height: 300px !important;
      &.creative{
        padding: 40px 10px;
      }
    }
  }
  h5{
    color: #848e9d;
    font-weight: 800;
    font-size: 28px;
    span{
      font-size: 50px;
      color: #000;
    }
  }
  h4{
    color: #000;
    font-weight: 800;
    font-size: 30px;
    position: relative;
    span{
      color: $brand-primary;
      font-weight: 900;
      font-size: 70px;
    }
    &:before{
      background: url("../images/out-bg.png") no-repeat;
      height: 26px;
      width: 37px;
      position: absolute;
      right: 50px;
      top: -20px;
    }
  }
  hr{
    width: 200px;
  }
  p{
    color: #696c70;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 35px;
  }
  .outer-box{
    position: relative;
    &:before{
      height: 53px;
      width: 53px;
      position: absolute;
      @include borderRadius(50%);
      background: #8bd848;
      top: -7px;
      left: 32px;
    }
    &.pink{
      &:before{
        background: #f64d7c;
        right: 0;
        left: 70px;
        margin: auto;
        top: -11px;
      }
    }
    &.yellow{
      &:before{
        right: -7px;
        background: #f1e000;
        left: inherit;
        top: -5px;
      }
    }
    &.brown{
      &:before{
        background: #f29550;
        top: 70px;
        left: 27px;
      }
    }
  }
  .box{
    background: white;
    text-align: center;
    padding: 70px 48px;
    @include borderRadius(30px);
    box-shadow: 0 0 10px rgb(162, 166, 169);
    position: relative;
    img{
      margin-bottom: 30px;
    }
    p{
      font-weight: 500;
      line-height: 23px;
      color: #000;
      font-size: 14px;
    }
    h6{
      font-size: 22px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 25px;
    }
    &.confidencial{
      height: 400px;
      margin-bottom: 35px;
    }
    &.creative{
      height: 260px;
      width: 100%;
      padding: 20px 48px;
      h6{
        margin-bottom: 20px;
      }
      img{
        margin-bottom: 20px;
      }
    }
    &.left{
      margin-left: 40px;
    }
    &.experienced{
      margin-bottom: 35px;
      margin-left: 0;
    }
    &.credit-base{
      margin-bottom: 0;
    }
  }
}

/* ==================================@media Quriy=============================== */

/*Small Screen*/
@media (min-width: 1280px) and (max-width: 1400px){
  .home-slider{
    height: 670px;
    .caption {
      padding-top: 7%;
      h1{
        font-size: 35px;
      }
      h4{
        font-size: 26px;
      }
    }
    .looking{
      width: 47%;
      padding-bottom: 15px;
    }
    .row{
      margin-top: -120px;
    }
  }
}
@media (max-width: 1350px) {
  .home-slider{
    height: 670px;
    .caption {
      padding-top: 7%;
      h1{
        font-size: 35px;
      }
      h4{
        font-size: 26px;
      }
    }
    .looking{
      width: 47%;
      padding-bottom: 15px;
    }
    .row{
      margin-top: -120px;
    }
  }
}


/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .home-slider {
    .caption {
      h1{
        font-size: 28px;
      }
      h4{
        font-size: 23px;
      }
    }
    .row{
      margin-top: 10px;
    }
    .looking{
      padding-bottom: 15px;
      font-size: 13px;
    }
  }
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .home-slider {
    height: 750px;
    .caption {
      h1{
        font-size: 28px;
      }
      h4{
        font-size: 23px;
      }
    }
    .row{
      margin-top: 10px;
    }
    .circle{
      width: 180px;
      z-index: 9;
      height: 180px;
      &:nth-child(2){
        left: 0;
        top: 30px;
        span{
          &.purple{
            top: 5px;
          }
        }
      }
      &:after{
        display: none;
      }
      h3{
        font-size: 16px;
        line-height: 20px;
      }
    }
    [class*=col]{
      width: 50%;
      float: left;
    }
    .looking{
      padding-bottom: 15px;
      font-size: 22px;
      width: 57%;
      bottom: 60px;
    }
    .right{
      .circle{
        &:nth-child(2){
          bottom: -30px;
        }
      }
    }
  }
  .our-industry{
    .txt h2 {
      text-align: center;
      margin-bottom: 30px;
      br{display: none}
    }
  }
  .stand_out {
    .box{
      padding: 40px 10px;
      &.confidencial{
        height: 350px;
      }
      &.creative{
        padding: 20px 10px;
        height: 300px;
      }
      &.left{
        margin-left: 0;
      }
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .home-slider {
    height: 750px;
    margin-top: -140px;
    .caption {
      padding-top: 20%;
      h1{
        font-size: 28px;
      }
      h4{
        font-size: 23px;
      }
    }
    .row{
      margin-top: 10px;
    }
    .circle{
      width: 180px;
      z-index: 9;
      height: 180px;
      &:nth-child(2){
        left: 0;
        top: 30px;
        span{
          &.purple{
            top: 5px;
          }
        }
      }
      &:after{
        display: none;
      }
      h3{
        font-size: 16px;
        line-height: 20px;
      }
    }
    [class*=col]{
      width: 50%;
      float: left;
    }
    .looking{
      padding-bottom: 15px;
      font-size: 22px;
      width: 57%;
      bottom: 60px;
    }
    .right{
      .circle{
        &:nth-child(2){
          bottom: -30px;
        }
      }
    }
  }
  .our-industry{
    background: white;
    .txt h2 {
      text-align: center;
      margin-bottom: 30px;
      font-size: 24px;
      br{display: none}
    }
    ul{
      li{
        font-size: 16px;
        &:after{
          display: block;
        }
        &:nth-child(even){
          &:after{
            display: none;
          }
        }
      }
    }
  }
  .stand_out {
    background: #eef0f3;
    .box{
      margin-top: 40px;
      padding: 40px 10px;
      &.confidencial{
        height: auto;
      }
      &.creative{
        padding: 20px 10px;
        height: auto;
      }
      &.left{
        margin-left: 0;
      }
    }
    h4{
      span{
        display: block;
        font-size: 60px;
      }
      &:before{display: none}
    }
  }
  .branding-solution{
    overflow: hidden;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .home-slider {
    .caption{
      padding-top: 32%;
      h1{
        font-size: 24px;
        line-height: 30px;
      }
      h4{
        font-size: 18px;
      }
      a{
        margin-top: 20px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
      }
    }
    .circle{
      width: 150px;
      z-index: 9;
      height: 150px;
      &:nth-child(2) {
        span{
          &.purple{
            height: 33px;
            width: 33px;
          }
        }
      }
    }
    .right{
      .circle {
        span{
          &.green{
            height: 33px;
            width: 33px;
          }
        }
      }
    }
    .row{
      margin-top: 50px;
    }
    .looking{
      padding-bottom: 15px;
      font-size: 22px;
      bottom: 0;
      border: none;
      left: 6px;
      width: 100%;
      text-shadow: 0 0 5px #000;
    }
  }
  .our-industry {
    ul {
      li{
        width: 50%;
        margin-bottom: 30px;
        &:last-of-type{
          margin: auto;
          display: table;
          width: 100%;
        }
      }
    }
  }
  .stand_out{
    h5{
      font-size: 20px;
      span{
        font-size: 35px;
      }
    }
    h4{
      font-size: 25px;
      span{
        font-size: 44px;
      }
    }
    br{
      display: none;
    }
    p{
      font-size: 14px;
      line-height: 24px;

    }
  }
}


