.carouselTicker__item img{
  max-width: 100%;
  margin: auto;
  margin-bottom: -1px;
}
.carouselTicker__item{
  background: white;
  box-shadow: 0 0 5px #000;
  padding: 4px;
  border-radius: 6px;
  padding-bottom: 10px;
}
ul.porfolio-slider li, ul.porfolio-slider2 li{
  padding: 5px;
  padding-top: 3px;
}

.subhead{
  h2{
    color: $brand-primary !important;
    line-height: 50px;
    margin-bottom: 20px !important;
  }
  p{
    color: #696c70;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 35px;
  }
}
.bg_transparent{
  background: transparent !important;
}
.portfolio{
  background: url("../images/portfolio-bg.jpg") top center no-repeat;
  width: 100%;
  background-size: cover;
  h2{
    font-size: 48px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    margin-bottom: 60px;
  }
  .btn-cus{
    background: $brand-success;
    border: 2px solid $brand-success;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    height: 47px;
    display: table;
    margin: auto;
    margin-top: 36px;
    padding: 0 50px;
    @include borderRadius(50px);
    line-height: 47px;
    @include transition(0.4s);
  }
}


.video-pf .carouselTicker__item img {
  height: 360px;
  margin-top: -45px;
}
section.portfolio.video-pf ul.porfolio-slider li a,
ul.porfolio-slider2 li a {
  display: block;
  overflow: hidden;
  height: 270px !important;
}
section.portfolio.video-pf .carouselTicker__list {
  height: 272px;
}
section.portfolio.video-pf .carouselTicker__item {
  margin: 5px;
  width: 480px;
  height: 360px;
  text-align: center;
  float: left;
}
ul.porfolio-slider li a,
ul.porfolio-slider2 li a {
  display: block;
}

ul.porfolio-slider li,
ul.porfolio-slider2 li {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.portfoliolist-overlay a {
  color: #fff;
  font-size: 16px;
  /*text-shadow: #000000b0 0px 1px 2px;*/
  height: 45vh;
  padding-top: 123px;
  display: block;
  letter-spacing: 1px;
  font-weight: 600;
}

.portfoliolist-overlay a:before,
.portfoliolist-overlay a:after {
  position: absolute;
  height: 1px;
  bottom: 121px;
  background: #fff;
  -webkit-transition: all .5s ease-in;
  -moz-transition: all .5s ease-in;
  -o-transition: all .5s ease-in;
  -ms-transition: all .5s ease-in;
  text-shadow: none;
  transition: all .5s ease-in;
  width: 0;
  content: "";
}

.portfoliolist-overlay a:after {
  right: 0;
}

.portfoliolist-overlay a:before {
  left: 0;
}

.portfoliolist-overlay a span {
  position: absolute;
  top: 0;
  text-align: center;
  left: 55px;
  font-weight: 500;
  width: 133px;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-size: 17px;
  -webkit-transition: all .4s ease-in;
  -moz-transition: all .4s ease-in;
  -o-transition: all .4s ease-in;
  -ms-transition: all .4s ease-in;
  transition: all .4s ease-in;
}

ul.porfolio-slider li:hover .portfoliolist-overlay span,
ul.porfolio-slider2 li:hover .portfoliolist-overlay span {
  top: 95px;
}

.porfolio-list [class^="col-"]:hover>div .overlay-portfolio h6 {
  color: #fff;
}

section.services-description img {
  max-width: 410px;
}

ul.porfolio-slider li:hover .portfoliolist-overlay a:before,
ul.porfolio-slider2 li:hover .portfoliolist-overlay a:before,
ul.porfolio-slider li:hover .portfoliolist-overlay a:after,
ul.porfolio-slider2 li:hover .portfoliolist-overlay a:after {
  width: 50px;
}

.portfoliolist-overlay i {
  -webkit-transition: all .4s ease-in;
  -moz-transition: all .4s ease-in;
  -o-transition: all .4s ease-in;
  -ms-transition: all .4s ease-in;
  transition: all .4s ease-in;
  margin-top: -2px;
  font-size: 16px;
}

ul.porfolio-slider li:hover .portfoliolist-overlay i,
ul.porfolio-slider2 li:hover .portfoliolist-overlay i {
  padding-left: 4px;
}


section.portfolio {
  color: #848894;
  padding: 80px 0;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  line-height: 23px;
}



section.portfolio .pd-lft50 {
  padding-left: 50px;
}
section.aboutus .pd-lft50 {
  padding-left: 50px;
}

section.portfolio .col-md-7 p {
  padding-top: 29px;
}


section.portfolio [class^="col-md-3"] {
  position: relative;
  margin-bottom: 0px;
  overflow: hidden;
  height: 284px;
  padding: 0 8px;
}

.toolbar.mb2.mt2 {
  padding: 20px  0px;
  text-align: center;

}

.toolbar.mb2.mt2 button {
  font-weight: 700;
  background: none;
  border: none;
  padding: 0 20px;
  position: relative;
  color: #282828;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  z-index: 1;
  margin-top: 0;
  /* padding: 0 30px; */
  margin: 0;
  outline: none;
}


section.portfolio.main-pf {
}

section.portfolio.main-pf h2 {
}

.toolbar.mb2.mt2 button:active {
  background: none;
  box-shadow: none;
}
section.portfolio .video [class^="col-md-3"] {
  height: 150px;
  margin-bottom: 20px;
}

section.portfolio .video [class^="col-md-3"] img {
  margin-top: -26px;
}
section.banner.inner-banner.work-ban {
  height: 300px;
}
.toolbar.mb2.mt2 button.active-pf, .btn:focus, .btn:hover {
  font-weight: 800;
  color: $brand-primary;
}






/* ==================================@media Quriy=============================== */

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  section.portfolio{
    padding: 40px 0;
    h2{
      font-size: 30px;
      margin-bottom: 10px;
      font-weight: 700;
      line-height: 54px;
      br{display: none}
    }
    img{
      max-width: 100%;
    }
    li{
      width: 200px;
      height: 160px;
    }
    .carouselTicker__list{
      height: 163px;
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}