footer{
  background: url("../images/footer-bg.jpg") no-repeat;
  width: 100%;
  background-size: cover;
  padding: 60px 0;
  h6{
    margin-top: 35px;
  }
  p{
    margin-top: 28px;
    color: #9894c3;
    font-size: 14px;
    line-height: 25px;
  }
  .ftr-info{
    li{
      i{
        margin-right: 10px;
      }
    }
  }
  .ftr_social{
    margin-top: 42px;
    li{
      display: inline-block;
      margin-right: 20px;
      i{
        color: $brand-primary;
        font-size: 20px;
      }
    }
  }
  ul{
    margin-top: 20px;
    li{
      margin-bottom: 20px;
      i{
        margin-right: 8px;
      }
      a{
        color: #9894c3;
        font-size: 13px;
      }
      &:last-of-type{
        margin: 0;
      }
    }
  }
}

.copyright{
  background: #5649d1;
  padding: 20px 0;
  p{
    color: #fff;
  }
  a{
    color: #fff;
    position: relative;
    padding-bottom: 5px;
  }
  ul{
    float: right;
    margin-top: 7px;
    li{
      display: inline-block;
      margin-right: 20px;
      &:last-of-type{
        margin: 0;
      }
    }
  }
}


/* ==================================@media Quriy=============================== */

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  footer ul li a{
    word-wrap: break-word;
  }
  .copyright {
    padding: 20px 0;
    ul{
      float: left;
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  footer{
    padding: 30px 0;
    br{display: none}
    p{
      width: 100%;
      font-size: 12px;
    }
    .col-sm-2{
      display: inline-block;
      width: 50%;
      float: left;
      margin-bottom: 30px;
    }
    h6{
      clear: both;
      margin-top: 20px;
    }
    ul{
      margin-top: 15px;
    }
  }
  .copyright {
    padding: 15px 0;
    p{
      font-size: 11px;
    }
    ul{
      float: none;
      margin-top: 5px;
      text-align: center;
      li{
        margin-right:8px;
        a{
          font-size: 11px;
        }
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  footer{
    .col-sm-3{
      clear: both;
    }
  }
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}