header{
  position: relative;
  padding: 30px 0;
  z-index: 1;
  nav{
    float: right;
    margin-top: 10px;
    ul{
      li{
        display: inline-block;
        margin-right: 50px;
        a{
          color: $brand-white;
          font-size: 15px;
          text-transform: uppercase;
          &.get-quote{
            display: inline-block;
            text-align: center;
            height: 45px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 42px;
            border: 2px solid #fff;
            width: 230px;
            border-radius: 50px;
          }
        }
        &:last-of-type{
          margin: 0;
        }
      }
    }
  }
}


.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-top: 5px;
  border-radius: 8px;
  li{
    display: block;
    margin: 0;
    a{
      color:#000 !important;
      font-size: 15px;
      text-transform: capitalize;
    }
  }
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content{
  li{
    &:before, &:after{display: none}
  }
}

  /* Change color of dropdown links on hover */
.dropdown-content a:hover {    background-color: #6e57c7; color: #fff !important;  }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #3e8e41;}



/* ==================================@media Quriy=============================== */

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  header nav ul li{
    margin-right: 30px;
  }
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .main-menu{
    margin-top: 0;
    background-image: -ms-linear-gradient(left, #582d96  0%, #3c2783  100%);
    background-image: -moz-linear-gradient(left, #582d96  0%, #3c2783  100%);
    background-image: -o-linear-gradient(left, #582d96  0%, #3c2783  100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #582d96 ), color-stop(100, #3c2783 ));
    background-image: -webkit-linear-gradient(left, #582d96  0%, #3c2783  100%);
    background-image: linear-gradient(to right, #582d96  0%, #3c2783  100%);
  }
  header {
    nav {
      ul {
        li{
          &:last-of-type{
            border-bottom: 0;
            margin-top: 15px;
            a{
              padding: 0;
            }
          }
          a{
            padding: 15px;
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .main-menu{
    margin-top: 0;
    background-image: -ms-linear-gradient(left, #582d96  0%, #3c2783  100%);
    background-image: -moz-linear-gradient(left, #582d96  0%, #3c2783  100%);
    background-image: -o-linear-gradient(left, #582d96  0%, #3c2783  100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #582d96 ), color-stop(100, #3c2783 ));
    background-image: -webkit-linear-gradient(left, #582d96  0%, #3c2783  100%);
    background-image: linear-gradient(to right, #582d96  0%, #3c2783  100%);
  }
  header {
    nav {
      padding: 10px 15px;
      ul {
        li{
          &:last-of-type{
            border-bottom: 0;
            margin-top: 15px;
            a{
              padding: 0;
            }
          }
          a{
            display: block;
            width: 100%;
            padding: 15px 0;
            &.get-quote{
              width: 100%;
            }
          }
        }
      }
    }
    ul.dropdown-content{
      li{
        a{
          color: #47298a !important;
          font-size: 14px;
        }
        &:last-of-type{
          margin-top: 0;
          a{
            padding: 15px;
          }
        }
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}