.inner-banner{
  position: relative;
  height: 599px;
  width: 100%;
  background-size: cover;
  margin-top: -124px;
  .caption{
    transform: translateY(-50%);
    text-align: center;
    position: absolute;
    top: 50%;
    margin: auto;
    right: 0;
    left: 0;
    a{
      font-size: 24px;
      color: $brand-success;
      border: 2px solid $brand-success;
      font-weight: 600;
      height: 51px;
      display: table;
      margin: auto;
      margin-top: 30px;
      padding: 0 50px;
      line-height: 50px;
      @include borderRadius(50px);
    }
    h1{
      font-size: 50px;
      font-weight: 700;
      line-height: 73px;
      color: #fff;
      span{
        color: $brand-success;
        font-style: italic;
      }
    }
  }
  &.contact{
    background: url("../images/contact-banner.jpg") no-repeat top center;
  }
  &.branding-solution-b{
    background: url("../images/contact-banner.jpg") no-repeat top center;
  }
  &.logo-design{
    background: url("../images/logo-design-banner.jpg") no-repeat top center;
  }
  &.video-animation{
    background: url("../images/video-animation-banner.jpg") no-repeat top center;
  }
  &.web-development-banner{
    background: url("../images/web-development-banner.jpg") no-repeat top center;
  }
}


/* ==================================@media Quriy=============================== */

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .inner-banner{
    height: 400px;
    .caption{
      top: 60%;
      h1{
        font-size: 22px;
        font-weight: 700;
        line-height: 37px;
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}