.branding-sol-pack{
  .pack{
    ul{
      height: 900px !important;
    }
  }
}

.our-packages{
  h2{
    font-size: 42px;
    color: #000;
    font-weight: 900;
    line-height: 45px;
    margin-bottom: 15px;
    span{
      color: $brand-primary;
    }
  }
  h3{
    color: #848e9d;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
  }
  h6{
    color: #000;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
  }
  p{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    line-height: 24px;
  }
  .nav-tabs{
    border-bottom: 4px solid #fcf653;
    margin-bottom: 40px;
    li{
      &:last-of-type{
        a{
          margin-right: 0
        }
      }
      a{
        border-radius: 0;
        border: 0;
        font-size: 17px;
        font-weight: 600;
        color: #1a183c;
        margin: 0;
        padding: 6px 19px;
        margin-right: 0;
        margin-bottom: 20px;
        &.active{
          color: #5649d1;
        }
      }
    }
  }
  .nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus{
    border: none;
    color: #fff;
    background: #5649d1;
  }
  .tab-content{
    h4{
      font-size: 30px;
      color: #000;
      font-weight: 700;
      text-align: center;
    }
  }
  p{
    color: #71777a;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    float: right;
    margin-top: 15px;
    span{
      color: red;
    }
  }
  .pack{
    background: #49d0bb;
    min-height: 687px;
    padding: 30px 20px;
    border-radius: 8px;
    margin-top: -6px;
    .head{
      border-bottom: 1px solid #24bea6;
      padding-bottom: 30px;
      margin-bottom: 30px;
      h5{
        font-size: 18px;
        color: #000;
        font-weight: 700;
        display: inline-block;
        text-transform: uppercase;
      }
      h4{
        font-size: 32px;
        color: #fff;
        font-weight: 700;
        float: right;
      }
    }
    ul{
      height: 635px;
      li{
        line-height: 24px;
        font-size: 14px;
        color: #000;
        font-weight: 600;
        margin-bottom: 20px;
        position: relative;
        padding-left: 25px;
        &:before{
          background: url("../images/pack-check.png") no-repeat;
          height: 16px;
          width: 16px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    a{
      height: 48px;
      text-align: center;
      color: #fff;
      background: $brand-primary;
      display: block;
      font-size: 18px;
      font-weight: 600;
      line-height: 48px;
      border-radius: 50px;
    }
    &.silver{
      background: #edaf56;
      .head{
        border-color: #d67d2d;
      }
    }
    &.gold{
      background: #6ccafa;
      .head{
        border-color: #4db0e3;
      }
    }
    &.purple{
      background: #aca4fd;
      .head{
        border-color: #968df1;
      }
    }
  }
}


/* ==================================@media Quriy=============================== */

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .our-packages {

    br{display: none}
    h2{
      font-size: 25px;
      font-weight: 700;
      line-height: 37px;
      margin-bottom: 0;
    }
    h3{
      font-size: 16px;
      font-weight: 500;
    }
    .nav-tabs{
      border-bottom: 3px solid #fcf653;
      margin-bottom: 15px;
      li{
        a{
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
    }

    [class*=col]{
      margin-top: 35px;
      clear: both;
      width: 100%;
    }
    .col-sm-3{
      margin-top: 0;
    }
    .pack {
      min-height: auto;
      ul{
        height: auto;
      }
    }
    p{
      margin-top: 5px;
      font-size: 12px;
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}