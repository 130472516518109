/* ==================================@media Quriy=============================== */

/* samsung s4 potrait */
@media (min-width: 640px) and (max-width: 640px){
}

/* samsung s4 landscape */
@media (min-width: 360px) and (max-width: 360px)
{
}

/* Mackbook */
@media (min-width: 1280px) and (max-width: 1280px)
{
}


/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}