.branding-solution{
  background: url("../images/branding-solution.jpg") no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover;
  position: relative;
  margin: 15px 0;
  &:after, &:before{
    height: 4px;
    width: 100%;
    background: black;
    position: absolute;
    top: -10px;
  }
  &:after{
    top: inherit;
    bottom: -10px;
  }
  h2{
    font-size: 56px;
    color: #000;
    font-weight: 900;
    line-height: 57px;
    span{
      color: $brand-primary;
      font-size: 60px;
      &.off{
        font-size: 80px;
      }
    }
  }
  ul{
    float: left;
    margin-left: 30px;
    li{
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      font-weight: 700;
      color: #000;
      margin-bottom: 15px;
      width: calc(100% / 2);
      float: left;
      position: relative;
      strong{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 12px;
        width: 100%;
        display: block;
      }
      span{
        width: 100%;
        font-size: 13px;
        margin-bottom: 12px;
        display: block;
      }
      &:before{
        content: '';
        height: 19px;
        width: 19px;
        position: absolute;
        top: 0;
        left: -27px;
        background: #ff7827;
        border-radius: 50%;
      }
      &:nth-child(2){
        &:before{background: #3debe7}
      }
      &:nth-child(3){
        &:before{background: #a2ff00}
      }
      &:nth-child(4){
        &:before{background: #eb4454}
      }
      &:nth-child(5){
        &:before{background: #7c71e8}
      }
      &:nth-child(6){
        &:before{background: #f6ff00}
      }
    }
  }
  .price{
    background: url("../images/branding-solution-price.png") no-repeat;
    height: 254px;
    width: 272px;
    position: relative;
    margin-top: -40px;
    .txt{
      position: absolute;
      top: 86px;
      text-align: center;
      left: 0;
      right: 0;
      h3{
        font-size: 58px;
        font-weight: 800;
        margin-bottom: 8px;
      }
      h4{
        font-size: 30px;
        color: #fce253;
        font-weight: 600;
        position: relative;
        &:after{
          content: '';
          background: #0a0000;
          height: 3px;
          width: 110px;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
  p{
    font-size: 22px;
    color: #e1480c;
    font-weight: 900;
    a{
      color: #000;
    }
  }
  h5{
    text-align: center;
    margin-top: 35px;
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 8px;
    span{
      font-size: 30px;
      display: block;
    }
  }
}


/* ==================================@media Quriy=============================== */

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .branding-solution{
    height: auto;
    margin-top: 0;
    background-color: #fbdf5a;
    br{
      display: none;
    }
    h2{
      font-size: 22px;
      text-align: left;
      font-weight: 700;
      line-height: 32px;
      span{
        font-size: 30px;
        &.off{
          font-size: 40px;
        }
      }
    }
    [class*=col]{
      margin-bottom: 35px;
      clear: both;
    }
    ul{
      li{
        strong{
          font-size: 17px;
        }
        span{
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
    .price{
      margin: auto;
      background-size: 100%;
      width: 182px;
      height: 174px;
      .txt{
        top: 46px;
        h3{
          font-size: 35px;
        }
        h4{
          font-size: 22px;
        }
      }
    }
    p{
      text-align: center;
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}