.home-slider2{
  .row{
    margin-top: 0;
  }
  .caption{
    padding-top: 10%;
    h1{
      text-align: left;
      font-size: 48px;
      line-height: 60px;
      margin-top: 150px;
    }
    h4{
      text-align: left;
    }
    a{
      display: inline-block;
    }
    .wrapper{
      background: rgba(54, 25, 108, 0.63);
      padding: 35px 50px;
      border-radius: 50px;
      a.full{
        border: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 0;
        margin: 0;
        z-index: 99;
      }
      h5{
        text-align: center;
        font-size: 30px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 32px;
      }
      .shadow{
        margin-bottom: 15px;
        position: relative;
        &:nth-of-type(1){
          .box{
            h4{
              strong{
                letter-spacing: 0.7px;
              }
            }
          }
          &:after{
            top: -12px;
            right: 180px;
          }
        }
        i{
          height: 45px;
          width: 45px;
          border-radius: 50%;
          background: #4b309e;
          line-height: 36px;
          position: absolute;
          left: -50px;
          border: 4px solid #a518f5;
          box-shadow: 0 0 2px #cab5b5;
          @include transition(0.3s linear);
          opacity: 0;
          text-align: center;
          color: #fcf653;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &:after{
         height: 53px;
         width: 53px;
         background: #c78c27;
         border-radius: 50%;
          position: absolute;
          top: 0;
          right: 0;
       }
        &:nth-of-type(2){
          &:after{
            background: #3ebcb8;
            right: inherit;
            left: 0;
          }
        }
        &:nth-of-type(3){
          &:after{
            background: #d5477c;
            right: -10px;
            top: 24px;
          }
        }
        &:nth-of-type(4){
          &:after{
            background: #8fde4a;
            top: inherit;
            bottom: -15px;
            left: 70px;
            right: inherit;
          }
        }
        &:hover{
          .box{
            border-color: #a919fb;
            a{
              color: #fff;
              background: #6541d8;
              border-color: #6541d8;
            }
          }
          i{
            left: -40px;
            opacity: 1;
          }
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
      .box{
        background: #ffffff;
        height: 90px;
        border: 2px solid #fff;
        padding: 25px 30px;
        z-index: 9;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include transition(0.5s);
        left: 0;
        h6{
          display: inline-block;
          color: #717178;
          font-size: 13px;
          font-weight: 500;
          margin-right: 20px;
        }
        h4{
          margin-bottom: 5px;
          font-size: 18px;
          font-weight: 600;
          color: #000000;
          line-height: 24px;
          strong{
            font-size: 20px;
          }
        }
        a{
          float: right;
          width: 123px;
          background: #fcf653;
          font-size: 15px;
          margin: 0;
          color: #000000;
          padding: 0;
          text-align: center;
          box-shadow: 0 0 20px #bebece;
          position: relative;
          z-index: 9999999999;
          height: 39px;
          line-height: 38px;
          &:hover{
            color: #fff;
            background: #6541d8;
            border-color: #6541d8;
          }
        }
        &:hover{
          a{
            color: #fff;
            background: #6541d8;
            border-color: #6541d8;
          }
        }
        .left{
          text-align: left;
          float: left;
          width: 70%;
        }
      }
    }
  }
}



/* ==================================@media Quriy=============================== */

@media (max-width: 1350px){
  .home-slider2{
    height: auto;
    .caption{
      padding-top: 5%;
      h1{
        margin-top: 100px;
      }
      .wrapper{
        padding: 25px 40px;
        .box{
          height: 80px;
        }
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1280px) {
  .home-slider2{
    height: auto;
    .caption{
      padding-top: 5%;
      h1{
        margin-top: 100px;
      }
      .wrapper{
        padding: 25px 40px;
        .box{
          height: 80px;
        }
      }
    }
  }
}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .home-slider2{
    height: auto;
    .caption {
      .wrapper {
        .box {
          a{
            width: 100%;
          }
        }
      }
    }
  }
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .branding-solution{
    overflow: hidden;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .home-slider2{
    height: auto;
    [class*=col]{
      width: auto;
    }
    .caption{
      a{
        margin-top: 14px;
      }
      padding-top: 35%;
      h1{
        margin-top: 0;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 5px;
        font-weight: 600;
      }
      .wrapper{
        width: 108%;
        border-radius: 20px;
        h4{
          text-align: left;
          font-weight: 500;
          font-size: 15px;
        }
        padding: 15px 10px;
        margin-top: 30px;
        h5{
          font-size: 20px;
          margin-bottom: 15px;
        }
        .box{
          display: block;
          padding: 10px 20px;
          height: 48px;
          border-radius: 30px;
          .left{
            width: 100%;
          }
          h4{
            font-size: 14px;
            br{display: none}
          }
          h6{
            display: none;
          }
          a{
            margin-top: 15px;
            width: 100%;
            font-size: 14px;
            display: none;
          }
          i{
            display: none;
          }
        }
        .shadow{
          margin-bottom: 10px;
          &:nth-of-type(3){
            &:after{
              right: -4px;
              top: 17px;
            }
          }
          &:nth-of-type(2){
            &:after{
              left: -6px;
            }
          }
          &:nth-of-type(1){
            &:after{
              top: -7px;
            }
          }
          &:nth-of-type(4){
            &:after{
              bottom: -9px;
            }
          }
        }
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}