.client_reviews{
  padding-top: 120px;
  h2{
    font-weight: 900;
    font-size: 42px;
    line-height: 46px;
    color: #000;
    margin-bottom: 8px;
  }
  h3{
    font-weight: 900;
    font-size: 30px;
    line-height: 40px;
    color: #848e9d;
  }
  p{
    font-weight: 500;
    color: #000000;
  }
  .reviews{
    .items{
      padding: 12px;
      position: relative;
      z-index: 1;
      &:before{
        content: '';
        width: 29px;
        height: 29px;
        background: #90df4a;
        position: absolute;
        @include borderRadius(50%);
        top: 1px;
        right: 2px;
        z-index: 1;

      }
    }
    @-webkit-keyframes orbit {
      from { -webkit-transform:rotate(0deg) }
      to { -webkit-transform:rotate(360deg) }
    }
    .box{
      border: 2px solid $brand-primary;
      text-align: center;
      padding: 38px 20px;
      border-radius: 10px;
      position: relative;
      z-index: 9;
      background: white;
      p{
        font-weight: 500;
        color: #6e6e6e;
        margin-bottom: 15px;
        min-height: 260px;
      }
      .img{
        position: relative;
        height: 100px;
        width: 120px;
        display: table;
        margin: auto;
        &:before{
          content: '';
          background: url("../images/review_border.png") no-repeat;
          height: 90px;
          width: 93px;
          margin: auto;
          -webkit-animation-iteration-count:infinite;
          -webkit-animation-timing-function:linear;
          -webkit-animation-name:orbit;
          -webkit-animation-duration:20s;
          position: absolute;
          left: -4px;
          right: 0;
          top: 0;
        }
        img{
          -webkit-animation: none;
          max-width: 100%;
          border-radius: 50%;
          margin: auto;
          margin-top: 5px;
          width: auto;
        }
      }
      h5{
        font-weight: 800;
        color: #5649d1;
        text-transform: uppercase;
        margin-top: 20px;
      }
    }
    .owl-controls{
      position: absolute;
      top: -80px;
      right: 0;
    }
    .owl-nav{
      [class*='owl-']{
        margin: 5px;
        padding: 0;
        display: inline-block;
        cursor: pointer;
        height: 36px;
        width: 36px;
        color: #848e9d;
        background: #ecebf6;
        border-radius: 50%;
        font-size: 21px;
        line-height: 32px;
        border: 3px solid #fff;
        box-shadow: 0 0 8px #e4e1e1;
        text-align: center;
      }
    }
  }
}


/* ==================================@media Quriy=============================== */

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .client_reviews{
    padding-top: 0;
    br{display: none}
    h2{
      font-weight: 700;
      font-size: 30px;
    }
    h3{
      font-size: 20px;
      line-height: 20px;
    }
    .reviews{
      margin-top: 30px;
      .owl-controls{
        position: static;
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}
