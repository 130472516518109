@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');
@import "font-awesome";
@import "bootstrap";
@import "mixin";
@import "jquery.bxslider";
@import "responsive";
@import "owl.carousel";
/*@import "animate";*/
@import "resetcss";
@import "header";
@import "home";
@import "footer";
@import "jquery.mCustomScrollbar";
@import "owl-theme-default";
@import "custom";
@import "hovereffects";
@import "client_reviews";
@import "flaticon";
@import "branding_solution";
@import "our-packages";
@import "portfolio";
@import "fancybox";
@import "carousel-ticker";
@import "innerbanner";
@import "innerpage";
@import "popop";
@import "responsive-menu";
@import "homeslider2";
@import "thankyou";



