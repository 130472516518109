/* Large desktops and laptops */
@media (min-width: 1200px) {
  .stand_out{
    .outer-box{
      @include transition(0.5s);
      cursor: pointer;
      transform: scale(1);
      &:hover{
        transform: scale(1.02);
      }
    }
  }

  .home-slider{
    .circle{
      transform: scale(1);
      &:hover{
        transform: scale(1.05);
        @include transition-delay(0.2s);
      }
    }
  }
  .copyright{
    a{
      &:after{
        content: '';
        background: #fff;
        height: 1px;
        width: 0;
        @include transition(0.5s);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      &:hover{
        &:after{
          width: 100%;
        }
      }
    }
  }
  footer{
    .ftr_social{
      li{
        a{
          &:after{display: none}
        }
      }
    }
    ul{
      li{
        a{
          position: relative;
          padding-bottom: 5px;
          @include transition(0.5s);
          &:hover{
            color: #fff;
            &:after{
              width: 100%;
            }
          }
          &:after{
            content: '';
            background: #fff;
            height: 1px;
            width: 0;
            @include transition(0.5s);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }
  }
  header{
    nav {
      ul {
        li {
          position: relative;
          padding-bottom: 8px;
          &:last-of-type{&:after{display: none}&:before{display: none}}
          &:after{
            height: 27px;
            width: 27px;
            background: #3b53f6;
            border-radius: 50%;
            position: absolute;
            left: 5px;
            top: -6px;
            @include transition(0.3s);
            opacity: 0;
            @include transition-delay(0.3s);
          }
          &:before{
            background: $brand-success;
            height: 2px;
            width: 0;
            position: absolute;
            right: 0;
            bottom: 0;
            @include transition(0.3s linear);
          }
          &:hover{
            &:after{
              opacity: 0.8;
              left: -16px;
            }
            a{
              color: $brand-success;
            }
            &:before{
              width: 100%;
            }
          }
          a{
            position: relative;
            z-index: 9;
            @include transition(0.5s);
            &.get-quote{
              &:hover{
                background: $brand-success;
                border-color: $brand-success;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
  .home-slider {
    .caption {
      a{
        &.cus_btn{
          position: relative;
          z-index: 9;
          @include transition(0.4s);
          &:hover{
            border-color: $brand-success;
            background: $brand-success;
            color: #000;
          }
        }
      }
    }
  }
  .portfolio {
    .btn-cus{
      &:hover{
        color: $brand-success;
        background: transparent;
      }
    }
  }
  .dropdown-content li {
    &:after{display: none}
  }
}
