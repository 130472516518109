/*
Carousel Ticker
*/


/** * CSS style for carouselTicker **/

.carouselTicker__list {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  height: 284px;
}

.carouselTicker__item {
  margin: 5px;
  width: 281px;
  height: 282px;
  text-align: center;
  float: left;
}

.carouselTicker__loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background: #fff url("../images/loader.gif") center center no-repeat;
}


/** * CSS style for vertical direction **/

.carouselTicker_vertical .carouselTicker__list {
  margin: 0;
}

.carouselTicker_vertical .carouselTicker__item {
  margin: 0;
  width: 520px;
  height: 284px;
  text-align: center;
}

#carouselTicker .carouselTicker__item {
  width: auto;
  height: auto;
  line-height: normal;
  float: left;
}

.carouselTicker__item img {
  vertical-align: top;
}


/*
Carousel Ticker
*/


/** * CSS style for carouselTicker **/

.carouselTicker__list {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 6px;
  overflow: hidden;
  height: 284px;
}

.carouselTicker__item {
  margin: 5px;
  width: 281px;
  height: 282px;
  text-align: center;
  float: left;
}

.carouselTicker__loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff url("../images/loader.gif") center center no-repeat;
}


/** * CSS style for vertical direction **/

.carouselTicker_vertical .carouselTicker__list {
  margin: 0;
}

.carouselTicker_vertical .carouselTicker__item {
  margin: 0;
  width: 520px;
  height: 284px;
  text-align: center;
}

#carouselTicker .carouselTicker__item {
  width: auto;
  height: auto;
  line-height: normal;
  float: left;
}

.carouselTicker__item img {
  vertical-align: top;
}