.inner-header{
  background-image: linear-gradient(to right, #8a30dd, #7036cb, #5839b7, #4338a2, #32358c);
  padding: 15px 0;
  text-align: center;
  img{
    margin: auto;
  }
}
.thankyou{
  padding: 70px 0;
  text-align: center;
  h1{
    font-size: 50px;
    color: #000000;
    font-weight: 900;
    margin-bottom: 40px;
    span{
      color: #7331c8;
    }
  }
  p{
    font-size: 18px;
    color: #000000;
    margin-bottom: 5px;
    line-height: 28px;
  }
  h6{
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 40px;
    color: #4e33a6;
  }
  .noted{
    margin-top: 40px;
    font-size: 16px;
    color: #e51f23;
    line-height: 24px;
    font-weight: 500;
  }
  ul{
    margin: 30px 0;
    float: left;
    width: 100%;
    position: relative;
    &:before{
      height: 38px;
      width: 38px;
      border-radius: 50%;
      background: #d958ec;
      position: absolute;
      top: -20px;
      left: 90px;
      z-index: -1;
    }
    &:after{
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: #f75c3e;
      position: absolute;
      top: 0;
      right: 9px;
      z-index: -1;
    }
    li{
      background: #fcf653;
      text-align: center;
      height: 100px;
      border-radius: 50px;
      display: inline-block;
      float: left;
      width: 45%;
      box-shadow: 0 0 20px #afaaaa;
      position: relative;
      &:nth-of-type(1){
        &:before{
          height: 22px;
          width: 22px;
          border-radius: 50%;
          background: #58cbec;
          position: absolute;
          bottom: 20px;
          left: 40px;
        }
      }
      &:nth-of-type(3){
        &:before{
          height: 22px;
          width: 22px;
          border-radius: 50%;
          background: #58ec58;
          position: absolute;
          bottom: 20px;
          right: 40px;
        }
      }
      p{
        padding-top: 17px;
        font-size: 22px;
        font-weight: 800;
        color: #000000;
        text-transform: uppercase;
        line-height: 30px;
        a{
          display: block;
          color: #32358c;
          font-size: 28px;
        }
      }
      &.or{
        box-shadow: none;
        margin-top: 20px;
        width: 10%;
        height: auto;
        background: transparent;
        font-size: 30px;
        color: #000000;
      }
    }
  }
  h5{
    clear: both;
  }
  .thank-pack{
    width: 450px;
    margin: auto;
    margin-top: 30px;
    background: #32358c;
    min-height: 201px;
    border-radius: 30px;
    position: relative;
    &:before{
      height: 22px;
      width: 22px;
      border-radius: 50%;
      background: #58ec58;
      position: absolute;
      bottom: 90px;
      left: 16px;
      z-index: 9;
    }
    &:after{
      height: 38px;
      width: 38px;
      border-radius: 50%;
      background: #d958ec;
      position: absolute;
      bottom: -3px;
      right: -3px;
      z-index: -1;
    }
    .head{
      border-radius: 30px 30px 0 0;
      background: #3f43a9;
      padding: 30px;
      position: relative;
      &:before{
        height: 22px;
        width: 22px;
        border-radius: 50%;
        background: #58cbec;
        position: absolute;
        top: -10px;
        right: -120px;
        left: 0;
        margin: auto;
      }
      h4{
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        strong{
          text-align: right;
          font-size: 30px;
          float: right;
          margin-top: -4px;
        }
      }
    }
    .labl{
      background: #fcf653;
      padding: 15px 0;
      border-radius: 50px;
      width: 90%;
      margin: auto;
      margin-top: 38px;
      h5{
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        font-weight: 800;
      }
    }
  }
}










/* ==================================@media Quriy=============================== */

/* samsung s4 potrait */
@media (min-width: 640px) and (max-width: 640px){
}

/* samsung s4 landscape */
@media (min-width: 360px) and (max-width: 360px)
{
}

/* Mackbook */
@media (min-width: 1280px) and (max-width: 1280px)
{
}


/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .thankyou {
    br{
      display: none;
    }
    h1{
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 10px;
    }
    p{
      font-size: 15px;
      margin-bottom: 5px;
      line-height: 24px;
    }
    h6{
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 24px;
    }
    .noted{
      margin-top: 10px;
      font-size: 13px;
      margin-bottom: 0;
    }
    ul{
      li{
        width: 100%;
        height: 70px;
        p{
          padding-top: 15px;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          a{
            font-size: 22px;
          }
        }
        &.or{
          width: 100%;
          text-align: center;
          margin: 15px 0;
        }
      }
    }
    .thank-pack{
      width: 100%;
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
}

@media (max-width: 420px) {

}

@media (max-width: 320px) {

}