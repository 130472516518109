/*font-family: 'Roboto Condensed', sans-serif;
font-family: 'Raleway', sans-serif;*/
body, *{
  font-family: 'Poppins', sans-serif;
}
b, strong {
  font-weight: 700;
}
section{
  padding: 65px 0;
}
&:after, &:before{
  content: '';
  @include transition(0.5s);
}
.dot{
  width: 29px;
  height: 29px;
  @include borderRadius(50%);
  &.green{background: #90df4a}
  &.pink{background: #ff5081}
  &.brown{background: #fc9b53}
}
h1{}
h2{}
h3{}
h4{}
h5{
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
h6{
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
p{
  font-size: 14px;
  line-height: 25px;
}
a{
  &:hover, &:focus, &:visited{text-decoration: none}
}
hr{
  margin: 30px 0;
  border: 0;
  border-top: 5px solid #fcf653;
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  section {
    padding: 30px 0;
    overflow: hidden;
  }
  hr{
    margin: 15px 0;
    border: 0;
    border-top: 3px solid #fcf653;
  }
}