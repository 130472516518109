/*
Flaticon icon font: Flaticon
Creation date: 16/07/2018 08:46
*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/Flaticon.eot");
  src: url("../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
  url("../fonts/flaticon/Flaticon.woff") format("woff"),
  url("../fonts/flaticon/Flaticon.ttf") format("truetype"),
  url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before{
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-close:before { content: "\f100"; }
.flaticon-right-arrow:before { content: "\f101"; }
.flaticon-back:before { content: "\f102"; }

$font-Flaticon-close: "\f100";
$font-Flaticon-right-arrow: "\f101";
$font-Flaticon-back: "\f102";