
.pull-right{
  clear: both;
}
html{
  width:100%;
}

.menu-bottom{
  display: none;
  width: 40px;
  height: 40px;
  padding: 5px;
  float: right;
  position: relative;
  z-index: 999999;
  cursor: pointer;
  @include transition(0.5s);
  span{
    height: 2px;
    background: $brand-white;
    display: block;
    width: 100%;
    margin-bottom: 4px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &.click{
    @include transition(0.5s);
    span{
      &:nth-child(1){
        width: 0;
      }
      &:nth-child(2){
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        position: relative;
        top: 6px;
      }
      &:nth-child(3){
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}

/* ==================================@media Quriy=============================== */


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .menu-bottom{
    display: block;
    margin-top: 0;
  }
  html{
    right:0;
    @include transitions(0.5s);
    position: relative;
  }
  .menu-open{
    position: fixed;
    right:250px;
    @include transitions(0.5s);
    .main-menu{
      right:0;
      margin-top: 0;
    }


  }
  .main-menu{
    overflow: auto;
    position: fixed;
    right:-251px;
    width: 251px;
    top:0;
    @include transitions(0.5s);
    height:100%;
    ul{
      padding: 0;
      li{
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
        &:last-child{
          /*border: 0;*/
        }
        a{
          color: #fff !important;
        }
        i{
          color: #fff;
        }
      }
    }
    .top-nav{
      float: none;
      text-align: center;
      border-bottom: 1px solid #52100f;
      background: #9a1f1e;
      margin: 0;
      li{
        display: inline-block;
        width: auto;
        border-bottom: 0;
        margin-right: 10px;
        i{
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
    .menu{
      li{
        padding: 20px;
        margin: 0;
        a{
          font-size: 17px;
          color: #fff !important;
          font-weight: 500;
        }
        &:last-child{
          padding: 20px;
          margin: 0;
        }
      }
    }
    .top-info{
      background: #9a1f1e;
      li{
        border-bottom: 1px solid #52100f;
        padding: 20px 15px;
        margin: 0;
        i{
          color: $brand-white !important;
          font-size: 20px !important;
          margin-right: 5px !important;
        }
        &:last-child{
          border-bottom: 0;
          text-align: center;
          padding-top: 30px;
          sup{
            background: $brand-white;
            color: $brand-primary;
            border-radius: 0;
            padding: 8px 10px;
            font-size: 14px;
            line-height: 14px;
            margin-left: 5px;
          }
          i{
            font-size: 25px;
          }
        }
      }
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .menu-bottom{
    display: block;
    margin-top: -50px;
  }
  .menu-open{
    header .menu li.request a{
      background: #e60000;
      color: #fff !important;
      width: 100%;
      font-family: $brand-primary;
    }
  }
  html{
    right:0;
    @include transitions(0.5s);
    position: relative;
  }
  .menu-open{
    position: fixed;
    right:250px;
    @include transitions(0.5s);
    .main-menu{
      right:0;
    }

  }
  .main-menu{
    overflow: auto;
    position: fixed;
    right:-251px;
    width: 251px;
    top:0;
    @include transitions(0.5s);
    height:100%;
    li{
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      &:last-child{
        /*border: 0;*/
      }
      a{
        font-size: 17px;
        color: #fff !important;
        font-weight: 500;
      }
      i{
        color: #fff;
      }
    }
    .top-nav{
      float: none;
      text-align: center;
      border-bottom: 1px solid #52100f;
      background: #9a1f1e;
      margin: 0;
      li{
        display: inline-block;
        width: auto;
        border-bottom: 0;
        margin-right: 10px;
        i{
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
    .menu{
      li{
        padding: 10px;
        margin: 0;
        a{
          font-size: 17px;
          color: #fff !important;
          font-weight: 500;
        }
      }
    }
    .top-info{
      background: #9a1f1e;
      li{
        border-bottom: 1px solid #52100f;
        padding: 20px 15px;
        margin: 0;
        i{
          color: $brand-white !important;
          font-size: 20px !important;
          margin-right: 5px !important;
        }
        &:last-child{
          border-bottom: 0;
          text-align: center;
          padding-top: 30px;
          sup{
            background: $brand-white;
            color: $brand-primary;
            border-radius: 0;
            padding: 8px 10px;
            font-size: 14px;
            line-height: 14px;
            margin-left: 5px;
          }
          i{
            font-size: 25px;
          }
        }
      }
    }
  }
  .faq-main ul.tabs li{
    font-size: 13px;
    padding: 15px 16px;
  }
  .faq_left_bar{
    padding: 0 0;
  }
  .infocol_left{
    position:static;
  }
  .infocol_left.fixedMenu{
    display:none;
  }

}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .menu-bottom{
    display: block;
    margin-top: -50px;
  }
  html{
    right:0;
    @include transitions(0.5s);
    position: relative;
  }
  .menu-open{
    position: fixed;
    right:250px;
    @include transitions(0.5s);


  }
  .main-menu{
    a{
      width: 100%;
    }
    .request{
      a{
        width: 100%;
        background: $brand-primary !important;
        color: #fff !important;
      }
    }
    overflow: auto;
    position: fixed;
    right:-251px;
    width: 251px;
    top:0;
    @include transitions(0.5s);
    height:100%;
    ul{
      padding: 0;
      li{
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
        &:last-child{
          /*border: 0;*/
        }
        a{
          color: #fff !important;
        }
        i{
          color: #fff;
        }
      }
    }
    .top-nav{
      float: none;
      text-align: center;
      border-bottom: 1px solid #52100f;
      background: #9a1f1e;
      margin: 0;
      li{
        display: inline-block;
        width: auto;
        border-bottom: 0;
        margin-right: 10px;
        i{
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
    .menu{
      li{
        padding: 15px;
        margin: 0;
        a{
          font-size: 17px;
          color: #fff !important;
          font-weight: 500;
        }
        &:last-child{
          padding: 20px;
          margin: 0;
        }
      }
    }
    .top-info{
      background: #9a1f1e;
      li{
        border-bottom: 1px solid #52100f;
        padding: 20px 15px;
        margin: 0;
        i{
          color: $brand-white !important;
          font-size: 20px !important;
          margin-right: 5px !important;
        }
        &:last-child{
          border-bottom: 0;
          text-align: center;
          padding-top: 30px;
          sup{
            background: $brand-white;
            color: $brand-primary;
            border-radius: 0;
            padding: 8px 10px;
            font-size: 14px;
            line-height: 14px;
            margin-left: 5px;
          }
          i{
            font-size: 25px;
          }
        }
      }
    }
  }
  ul.main-menu li {
    display: block !important;
    border-bottom: 1px solid #e60000;
  }
  header .menu li.request{
    float: left;
    width: 100%;
  }


  .singleproduct .recommended-products .owl-controls .owl-nav .owl-prev, .singleproduct .recommended-products .owl-controls .owl-nav .owl-next{
    top: -16%;
  }
  .faq_left{

    float:none;
    width:100%;
  }
  .faq_right{
    float:none;
    width:100%;
  }
  .faq-main ul.tabs li.current::after{
    display:none;
  }
  .infocol_left{
    position:static;
  }
  .infocol_left.fixedMenu{
    display:none;
  }
}

